<template>
    <div class="project">
        <van-search v-model="keyWord" @input="watchKeyWord" placeholder="请输入隧道名称" />
        <div class="search-list" v-if="serachShow">
            <div class="info-row" v-for="(item,index) in filterData" :key="index" @click="goProject(item)">
                <div class="img">
                    <img :src=item.img :alt=item.name />
                </div>
                <div class="name">{{item.name}}</div>
            </div>
        </div>
        <div class="container">
            <van-row  gutter="10">
                <van-col span="12" v-for="(item,index) in listData" :key="index">
                    <div @click="goProject(item)">
                        <img class="project-img" :src=item.img :alt=item.name />
                        <div class='grid-demo-item-block'>{{item.name}}</div>
                    </div>
                </van-col>
            </van-row>
        </div>
    </div>
</template>

<script>
import tunnelImg from '@/assets/tunnel/img/tunnel.jpg'
import fenshuilingImg from '@/assets/tunnel/img/fenshuiling.jpg'
import anlingliangImg from '@/assets/tunnel/img/anlingliang.jpg'
import changcaoImg from '@/assets/tunnel/img/changcao.jpg'
import erdaoheImg from '@/assets/tunnel/img/erdaohe.jpg'
import jiudaoheImg from '@/assets/tunnel/img/jiudaohe.jpg'
export default {
    data(){
        return{
            listData:[
                {
                    name:'怀柔分水岭隧道',
                    id:'test0001',
                    img:fenshuilingImg,
                },
                {
                    name:'怀柔安岭梁隧道',
                    id:'test0002',
                    img:anlingliangImg,
                },
                {
                    name:'怀柔怀丰隧道',
                    id:'test0003',
                    img:tunnelImg,
                },
                {
                    name:'房山九道河隧道',
                    id:'test0004',
                    img:jiudaoheImg,
                },
                {
                    name:'房山二道河隧道',
                    id:'test0005',
                    img:erdaoheImg,
                },
                {
                    name:'房山长操隧道',
                    id:'test0006',
                    img:changcaoImg,
                },
                {
                    name:'延庆青龙桥隧道',
                    id:'test0007',
                    img:tunnelImg,
                },
                {
                    name:'延庆瓦庙隧道',
                    id:'test0008',
                    img:tunnelImg,
                }
            ],
            serachShow:false,
            keyWord:'',
            filterData:[]
        }
    },
    mounted(){},
    methods:{
        watchKeyWord(val){
            if(val.trim()){
                this.serachShow = true;
                this.filterData = this.listData.filter(v => v.name.includes(val))
            }else{
                this.serachShow = false;
                this.filterData = []
            }
        },
        goProject(item){
            this.$router.push({path:`/tunnel/home?id=${item.id}`});
        }
    }
}
</script>

<style lang="scss" scoped>
.search-list{
    padding:10px;
    .info-row{
        display: flex;
        align-items: center;
        padding: 10px 0;
        border-bottom: 1px solid #f0f0f0;
        .img{
            width: 20%;
            img{
                width: 40px;
                height: 40px;
                border-radius: 50%;
            }
        }
        .name{
            font-size: .85rem;
            text-align: left;
            width: 80%;
            color: #f15f4c;
        }
    }
}
.container{
  padding: 10px;
  .project-img{
    display: block;
    width: 100%;
    height: 110px;
    padding: 0;
    margin: 0;
  }
  .grid-demo-item-block {
    background: #e5876b;
    text-align: center;
    color: #fff;
    height: 100%;
    padding: 4px 6px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: .8rem;
    margin-bottom: 10px;
  }
}
.search-box{
  padding: 4px 6px;
  .adm-list-item-content-main{
    font-size: .95rem;
    line-height: 1.5;
    overflow: hidden;
    color: #f15f4c;
  }
  .adm-list-item-description{
    font-size: .85rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
// .adm-list{
//   position: absolute;
//   width: 100%;
// }
</style>